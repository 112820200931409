
.feedback-form .btn-outline-secondary {
    border: 1px solid #ced4da;
}

.animated-field {
    transition: 0.3s linear;
    max-height: 1000px;
    /*opacity: 1;*/
}

.hidden-field {
    max-height: 0;
    opacity: 0;
    margin-bottom: 0 !important;
    overflow: hidden;
}

/* validation customizations */

/* text boxes, scale, text areas and so on*/
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-control:valid {
    background-image: unset;
    padding-right: 0.75rem;
    border-color: #ced4da;
    box-shadow: inherit;
}
.was-validated .form-control:valid:focus {
    box-shadow: inherit;
}

/* check boxes*/
.was-validated .form-check-input:valid~.form-check-label {
    color: inherit;
}
.was-validated .form-check-input:valid:focus {
    box-shadow: inherit;
}
.was-validated .form-check-input:valid:checked {
    background-color: #0d6efd;
}
.was-validated .form-check-input:valid {
    border-color: rgba(0,0,0,.25);
}

/* scale field */
.was-validated :invalid~.validate-nps .btn {
    border-color: #dc3545;
}
.was-validated :valid~.validate-nps .btn {
    /*border-color: #198754;*/
}

/* centered styles */
.centered .invalid-feedback {
    text-align: center;
}

label.btn {
    padding-left: unset;
    padding-right: unset;
}