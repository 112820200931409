.text-center .rating-container .caption {
    display: block;
}

.rating-container .rating-stars:focus {
    outline: none;
}

.rating-container .rating-stars, .rating-container .rating-stars>span,.rating-container .rating-stars .star {
    height: auto;
    line-height: 0;
    vertical-align: top;
}

.rating-input {
    display: none;
}

.rating-container {
    display: flex;
    vertical-align: middle;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 576px) {
    .rating-container {
        justify-content: center;
    }
    .rating-container .caption {
        width: 100%;
        margin-top: 5px;
        text-align: center;
    }
}

.centered .rating-container {
    justify-content: center;
}

.centered .rating-container .caption {
    width: 100%;
    margin-top: 5px;
    text-align: center;
}


.rating-xl .rating-stars {
    width: calc(var(--star-width-multi)*48px + var(--star-width-multi) * 4px);
    height: 48px;
}
.rating-xl .rating-stars .empty-stars {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.rating-stars .star img {
    display: block;
    width: 48px;
}

.rating-container .rating-stars .star img {
    display: block;
    width: 100%;
}

/* custom sizes */


.star img::before {
    content: '';
    padding-top: 100%;
    float: left;
}
.star img::after {
    content: '';
    display: block;
    clear: both;
}

:root {
    --star-width-multi: 5;
    --star-screen-scale: 1;
}

/* size 10 */
.rating-container.size-10 .rating-stars {
    width: calc(var(--star-width-multi)*115px + var(--star-width-multi) * 4px);
    height: 115px;
}
.rating-container.size-10 .rating-stars .star {
    width: 115px;
}
.centered .rating-container.size-10 .caption {
    margin-top: 25px;
}

/* size 9 */
.rating-container.size-9 .rating-stars {
    width: calc(var(--star-width-multi)*100px + var(--star-width-multi) * 4px);
    height: 100px;
}
.rating-container.size-9 .rating-stars .star {
    width: 100px;
}
.centered .rating-container.size-9 .caption {
    margin-top: 20px;
}

/* size 8 */
.rating-container.size-8 .rating-stars {
    width: calc(var(--star-width-multi)*85px + var(--star-width-multi) * 4px);
    height: 85px;
}
.rating-container.size-8 .rating-stars .star {
    width: 85px;
}
.centered .rating-container.size-8 .caption {
    margin-top: 15px;
}

/* size 7 */
.rating-container.size-7 .rating-stars {
    width: calc(var(--star-width-multi)*70px + var(--star-width-multi) * 4px);
    height: 70px;
}
.rating-container.size-7 .rating-stars .star {
    width: 70px;
}
.centered .rating-container.size-7 .caption {
    margin-top: 10px;
}

/* size 6 */
.rating-container.size-6 .rating-stars {
    width: calc(var(--star-width-multi)*60px + var(--star-width-multi) * 4px);
    height: 60px;
}
.rating-container.size-6 .rating-stars .star {
    width: 60px;
}
.centered .rating-container.size-6 .caption {
    margin-top: 7.5px;
}

/* size 5 */
.rating-container.size-5 .rating-stars {
    width: calc(var(--star-width-multi)*50px + var(--star-width-multi) * 4px);
    height: 50px;
}
.rating-container.size-5 .rating-stars .star {
    width: 50px;
}
.centered .rating-container.size-5 .caption {
    margin-top: 5px;
}

/* size 4 */
.rating-container.size-4 .rating-stars {
    width: calc(var(--star-width-multi)*45px + var(--star-width-multi) * 4px);
    height: 45px;
}
.rating-container.size-4 .rating-stars .star {
    width: 45px;
}

/* size 3 */
.rating-container.size-3 .rating-stars {
    width: calc(var(--star-width-multi)*40px + var(--star-width-multi) * 4px);
    height: 40px;
}
.rating-container.size-3 .rating-stars .star {
    width: 40px;
}

/* size 2 */
.rating-container.size-2 .rating-stars {
    width: calc(var(--star-width-multi)*35px + var(--star-width-multi) * 4px);
    height: 35px;
}
.rating-container.size-2 .rating-stars .star {
    width: 35px;
}

/* size 1 */
.rating-container.size-1 .rating-stars {
    width: calc(var(--star-width-multi)*30px + var(--star-width-multi) * 4px);
    height: 30px;
}
.rating-container.size-1 .rating-stars .star {
    width: 30px;
}

/* new common logic */
.rating-container .rating-stars {
    width: calc(var(--star-width-multi)*var(--star-screen-scale)*25px + var(--star-width-multi) * 4px) !important;
    height: calc(var(--star-screen-scale) * 25px) !important;
}
.rating-container .rating-stars .star {
    width: calc(var(--star-screen-scale) * 25px) !important;
}

/* star sizes */
.stars-10 {
    --star-width-multi: 10;
}
.stars-9 {
    --star-width-multi: 4;
}
.stars-8 {
    --star-width-multi: 4;
}
.stars-7 {
    --star-width-multi: 4;
}
.stars-6 {
    --star-width-multi: 4;
}
/* 5 is default */
.stars-4 {
    --star-width-multi: 4;
}
.stars-3 {
    --star-width-multi: 3;
}
.stars-2 {
    --star-width-multi: 2;
}
.stars-1 {
    --star-width-multi: 1;
}

/* label position */
.centered.star-labels-2 .rating-container .rating-stars {
    order: 1;
}

.centered.star-labels-2 .rating-container .caption {
    order: 2;
}

.centered.star-labels-1 .rating-container .rating-stars {
    order: 2;
    margin-bottom: 10px;
}

.centered.star-labels-1 .rating-container>.caption,.centered.star-labels-1 .rating-container>.caption {
    order: 1;
    margin-top: 0;
    margin-bottom: 5px;
}

.caption-badge {
    font-family: inherit;
}